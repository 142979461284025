import React from 'react';
import styles from './styles.module.scss';

const TreeItem = ({ node, onSelect, showCount }) => {
	return (
		<div className={styles.treeItem} onClick={() => onSelect(node)}>
			{node.label}
			{showCount && node.count && <span className={styles.count}>{node.count}</span>}
		</div>
	);
};

export default TreeItem;
