'use client';
import React, { useEffect, useRef, useState } from 'react';
import styles from './dropdown.module.scss';
import { MdCheckCircle } from 'react-icons/md';
type TriggerType = 'click' | 'hover';
type PlacementType = 'bottomLeft' | 'bottomRight'; // Add more if needed

interface DropDownProps {
	overlay: Array<{
		key: string;
		label: string;
		onClick: () => void;
		icon?: React.ReactNode;
		disabled?: boolean;
		selected?: boolean;
	}>;
	visible?: boolean;
	onVisibleChange?: (visible: boolean) => void;
	trigger?: TriggerType;
	placement?: PlacementType;
	children: React.ReactNode;
	itemClassName?: string;
}

const DropDown: React.FC<DropDownProps> = ({
	overlay = [],
	visible = false,
	onVisibleChange,
	trigger = 'click',
	placement = 'bottomLeft',
	children,
	itemClassName
}) => {
	const [isOpen, setIsOpen] = useState(visible);
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setIsOpen(visible);
	}, [visible]);

	useEffect(() => {
		const clickListener = (event: Event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
				if (onVisibleChange) onVisibleChange(false);
			}
		};
		document.addEventListener('click', clickListener);

		return () => {
			document.removeEventListener('click', clickListener);
		};
	}, [onVisibleChange]);

	const toggleDropdown = () => {
		if (trigger === 'click') {
			setIsOpen((prev) => !prev);
			if (onVisibleChange) onVisibleChange(!isOpen);
		}
	};

	const handleMouseOver = () => {
		if (trigger === 'hover') {
			setIsOpen(true);
			if (onVisibleChange) onVisibleChange(true);
		}
	};

	const handleMouseOut = () => {
		if (trigger === 'hover') {
			setIsOpen(false);
			if (onVisibleChange) onVisibleChange(false);
		}
	};

	return (
		<div
			className={styles.dropdown}
			ref={dropdownRef}
			onMouseOver={trigger === 'hover' ? handleMouseOver : undefined}
			onMouseOut={trigger === 'hover' ? handleMouseOut : undefined}>
			<div className={styles['dropdown-toggle']} onClick={trigger === 'click' ? toggleDropdown : undefined}>
				{children}
			</div>
			{isOpen && (
				<div className={`${styles['dropdown-menu']} ${styles[placement]}`}>
					{overlay.map((item) => (
						<div
							key={item.key}
							style={{
								cursor: item.disabled ? 'not-allowed' : 'pointer',
								display: 'flex',
								// justifyContent: 'space-between',
								alignItems: 'center'
							}}
							className={`${itemClassName} ${item.disabled ? styles.disabled : ''} ${item.selected ? styles.selected : ''}`}
							onClick={(action) => {
								if (item.disabled) return;
								item.onClick();
								setIsOpen(false);
							}}>
							{item.icon} {item.label}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default DropDown;
