import React from 'react';
import styles from './card.module.scss';
import cls from 'classnames';

interface CardProps {
	title?: React.ReactNode;
	extra?: React.ReactNode;
	loading?: boolean;
	bordered?: boolean;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	className?: string;
	bodyStyle?: React.CSSProperties;
	size?: 'small' | 'medium' | 'large'; // Add this line
	headerStyle?: React.CSSProperties;
	noColor?: boolean;
	style?: React.CSSProperties;
	ref?: any;
	onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
	title,
	extra,
	loading = false,
	bordered = true,
	children,
	footer,
	className,
	bodyStyle = {},
	size = 'medium', // Add this line
	headerStyle = {},
	noColor = false,
	style = {},
	ref,
	onClick
}) => {
	return (
		<div
			ref={ref}
			onClick={onClick}
			// className={`${styles.card} ${styles[size]}  ${className} ${
			// 	bordered ? styles.bordered : "" // Add this line
			// 	} ${noColor ? styles.noColor : ""}`}

			className={cls(
				styles.card,
				styles[size],
				className,
				bordered ? styles.bordered : '',
				noColor ? styles.noColor : '',
				noColor ? styles.hoverwhite : ''
			)}
			style={style}>
			{(title || extra) && (
				<div className={styles.cardHeader} style={headerStyle}>
					{title && <div className={styles.cardTitle}>{title}</div>}
					{extra && <div className={styles.cardExtra}>{extra}</div>}
				</div>
			)}
			{loading ? (
				<div className={styles.cardLoading}>Loading...</div>
			) : (
				<div className={styles.cardContent} style={bodyStyle}>
					{children}
				</div>
			)}

			{footer && <div className={styles.cardFooter}>{footer}</div>}
		</div>
	);
};

export default Card;
