import React, { useState, useRef, useEffect, Fragment } from 'react';
import { includes, get, forEach, isEmpty, isArray, slice, map } from 'lodash';
import cls from 'classnames';
import TreeItem from './TreeItem'; // Assuming TreeItem is in a separate file
import styles from './styles.module.scss';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
const deepSearch = (nodes, query, path = []) => {
	let results = [];
	forEach(nodes, (node) => {
		const nodeMatch = includes(node.label.toLowerCase(), query.toLowerCase());
		const newPath = [...path, node.label];

		if (nodeMatch && newPath.length === 3) {
			results.push({ node, path: newPath });
		}

		if (!isEmpty(node.children)) {
			results = [...results, ...deepSearch(node.children, query, newPath)];
		}
	});

	return results;
};

const groupByParent = (searchResults) => {
	const groups = {};
	forEach(searchResults, ({ node, path }) => {
		const parentPath = slice(path, 0, -1).join(' > ');
		if (!groups[parentPath]) {
			groups[parentPath] = { parent: parentPath, children: [] };
		}
		groups[parentPath].children.push(node);
	});
	return Object.values(groups);
};

const findParentNode = (nodes, value, path = []) => {
	for (const node of nodes) {
		const newPath = [...path, node];
		if (node.value === value) {
			return newPath;
		}

		if (!isEmpty(node.children)) {
			const foundPath = findParentNode(node.children, value, newPath);
			if (foundPath) return foundPath;
		}
	}
	return null;
};

const TreeSelect = ({ data, showCount, onChange, value, placeholder }) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [currentLevelData, setCurrentLevelData] = useState(data);
	const [path, setPath] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const dropdownRef = useRef(null);
	const [selectedLabelPath, setSelectedLabelPath] = useState([]);

	const handleNodeSelect = (node) => {
		if (path.length === 0 && typeof node.value === 'number') {
			const parentNode = findParentNode(data, node.value);
			const newPath = parentNode.map((node) => node.label);
			setPath(newPath);
			setSelectedLabelPath(newPath);
			setIsDropdownOpen(false);
			onChange && onChange(node.value);
			return;
		}

		setPath([...path, node.label]);
		setCurrentLevelData(node.children || []);
		if (!node.children || node.children.length === 0) {
			setIsDropdownOpen(false);
			setSelectedLabelPath([...path, node.label]);
			onChange && onChange(node.value);
		}
	};

	const handleBack = () => {
		const newPath = slice(path, 0, -1);
		setPath(newPath);
		let newLevelData = data;
		forEach(newPath, (step) => {
			const nextNode = get(
				newLevelData.find((node) => node.label === step),
				'children',
				[]
			);
			if (!isEmpty(nextNode)) {
				newLevelData = nextNode;
			} else {
				newLevelData = [];
			}
		});

		setCurrentLevelData(newLevelData);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	const filteredData = searchQuery ? groupByParent(deepSearch(data, searchQuery)) : [{ children: currentLevelData }];

	const getPathDisplay = (path) => {
		return map(path, (curr, index: any) => {
			if (index < path.length - 1) {
				return (
					<Fragment key={index}>
						{curr}
						<MdArrowForwardIos style={{ fontSize: 12, margin: '0 5px' }} />
					</Fragment>
				);
			} else {
				return curr;
			}
		});
	};

	useEffect(() => {
		if (isDropdownOpen) {
			setPath([]);
			setCurrentLevelData(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDropdownOpen]);

	useEffect(() => {
		if (value) {
			const parentNode = findParentNode(data, value);
			if (!isEmpty(parentNode)) {
				const newPath = map(parentNode, (node) => node.label);
				setPath(newPath);
				setSelectedLabelPath(newPath);
			} else {
				// Reset the path if no parent node is found
				setPath([]);
				setSelectedLabelPath([]);
			}
		}
	}, [value, data]);

	return (
		<div className={styles.treeSelectContainer} ref={dropdownRef}>
			<div
				className={cls(styles.dropdownTrigger, {
					[styles.dropdownTriggerOpen]: isDropdownOpen
				})}
				onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
				{selectedLabelPath.length > 0 ? (
					(getPathDisplay(selectedLabelPath) as any)
				) : (
					<span className='text-grey500 fw-300'>{placeholder || 'Select an option'}</span>
				)}
			</div>
			{isDropdownOpen && (
				<div className={styles.dropdownMenu}>
					<input
						type='text'
						placeholder='Search...'
						className={styles.searchInput}
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
					{path.length > 0 && (
						<div className={styles.navigation} onClick={handleBack}>
							<MdArrowBackIos style={{ fontSize: 12, margin: '0 5px' }} />
							{getPathDisplay(path)}
						</div>
					)}
					{isArray(filteredData) &&
						filteredData.map((group: any, key) => (
							<Fragment key={key}>
								{group.parent && <div className={styles.parentLabel}>{group.parent}</div>}
								{group.children.map((item) => (
									<TreeItem key={item.value} node={item} onSelect={handleNodeSelect} showCount={showCount} />
								))}
							</Fragment>
						))}
				</div>
			)}
		</div>
	);
};

export default TreeSelect;
